import qs from 'qs';
import HTTPError from '../lib/HTTPError';
import {getToken, setToken, redirectToLogin, logout} from '../utils/auth';
import {refreshToken} from '../utils/auth-token';

/**
 * Make a API request to the given endpoint
 *
 * @param {string} endpoint
 * @param {object} [opts]
 * @param {boolean} [disableAuth]
 * @returns {Promise}
 */
export async function request(endpoint, opts, disableAuth) {
	let url = `${globalThis.appConfig.apiUrl}/${endpoint}`;

	if (Object.keys(opts?.qs || {}).length) {
		url = `${url}?${qs.stringify(opts.qs)}`;
		delete opts.qs;
	}

	if (!disableAuth && !globalThis.testMode) {
		await renewToken();
	}

	opts = handleOptions(opts, disableAuth);

	// The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
	// Instead, it will resolve normally (with ok status set to false), and it will only reject on network failure or
	// if anything prevented the request from completing.
	let res;

	try {
		res = await fetch(url, opts);
	} catch (err) {
		if (err.name === 'AbortError') {
			throw err;
		}
		await handleError(res, url, opts);
	}

	if (!res.ok) {
		await handleError(res, url, opts);
	}

	return (res.status === 200 || res.status === 201) ? res.json() : res;
}

/**
 * Make an API request to the given endpoint and fetch all pages
 *
 * @param {string} endpoint
 * @param {number} [size]
 * @param {object} [opts]
 * @param {boolean} [disableAuth]
 * @returns {Promise}
 */
export async function requestAllPages(endpoint, size = false, opts = {}, disableAuth = false) {
	const [path, queryString] = endpoint.split('?');
	const params = queryString ? qs.parse(queryString) : {page: {}};

	let {meta, data} = await requestPage(path, params, 1);

	if (meta?.page) {
		const {page} = meta;

		if (page.number * page.size < page.total) {
			const totalPages = Math.ceil(page.total / page.size);

			for (let page = 2; page <= totalPages; page++) {
				const res = await requestPage(path, params, page);

				meta = Object.assign(meta, res.meta);

				if (Array.isArray(data) && Array.isArray(res.data)) {
					data = data.concat(res.data);
				}
			}
		}
	}

	return {meta, data};

	function requestPage(path, params, number) {
		params.page = {
			number,
			...(size && {size})
		};

		return request(path + '?' + qs.stringify(params, {encode: false}), opts, disableAuth);
	}
}

/**
 *
 * Process options and set proper headers automatically
 *
 * @param {object} opts
 * @param {boolean} disableAuth
 * @returns {object}
 */
export function handleOptions(opts, disableAuth) {
	const token = getToken();

	opts = Object.assign({}, {method: 'GET'}, opts);
	opts.headers = Object.assign({}, opts.headers);

	if (!disableAuth && !hasHeader(opts.headers, 'Authorization')) {
		opts.headers['Authorization'] = `Bearer ${token}`;
	}

	if (!hasHeader(opts.headers, 'Accept')) {
		opts.headers['Accept'] = 'application/vnd.dredition.v12+json';
	}

	if (opts.body && typeof (opts.body) !== 'string') {
		opts.body = JSON.stringify(opts.body);

		if (!hasHeader(opts.headers, 'Content-Type')) {
			opts.headers['Content-Type'] = 'application/json;charset=UTF-8';
		}
	}

	return opts;
}

/**
 * Check if a specific header is set
 *
 * @param {object} headers
 * @param {string} name
 * @returns {boolean}
 */
function hasHeader(headers, name) {
	return Object.keys(headers).some((key) => key.toLowerCase() === name.toLowerCase());
}

/**
 * Handle error response and convert it to an HTTPError
 *
 * @param {object} res
 * @param {string} url
 * @param {object} opts
 */
export async function handleError(res, url, opts) {
	const err = {
		message: 'Request failed',
		status: 0,
		statusText: '',
		headers: {},
		config: {
			method: opts.method,
			url
		}
	};

	if (res) {
		err.status = res.status;
		err.statusText = res.statusText;
		err.headers = Array.from(res.headers.entries()).reduce((o, p) => {
			o[p[0]] = p[1];
			return o;
		}, {});
	}

	try {
		err.data = await res.json();
	} catch (err) {
		// Silently ignore error if the response isn't JSON
	}

	throw new HTTPError(err);
}

/**
 * Renew expired token if needed
 */
async function renewToken() {
	const newToken = await refreshToken(getToken(), onSessionExpire);

	if (newToken) {
		setToken(newToken);
	}
}

/**
 * Called when session expired (failed to renew auth token)
 */
async function onSessionExpire() {
	await logout();
	redirectToLogin(location, false, 'Session expired');
}
