export default class BaseError extends Error {
	userMessage: string;
	isHandled: boolean;

	constructor(message: string) {
		super(message);
		this.name = 'BaseError';
		this.userMessage = message;
		this.isHandled = false;
	}
}
